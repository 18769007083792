document.addEventListener('turbolinks:load', () => {
  function hasExtraComments() {
    return $('#answer_content_array_0').val() !== '';
  }

  function otherOptionChecked() {
    const otherOption = $('#bf_final_question_options div:nth-child(5) input');
    return otherOption.prop('checked');
  }

  function displayExtraComments() {
    if (hasExtraComments() || otherOptionChecked()) {
      $('.q30-extra-comments').removeClass('d-none');
    }
  }

  displayExtraComments();

  $('.quiz-content__single-select').change(() => {
    if (otherOptionChecked()) {
      $('.q30-extra-comments').removeClass('d-none');
    }
  });
});
