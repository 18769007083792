import Clipboard from 'clipboard/dist/clipboard';

document.addEventListener('turbolinks:load', () => {
  $('.clipboard-btn').tooltip({
    trigger: 'click',
    placement: 'top',
  });

  function setTooltip(btn, message) {
    $(btn).attr('data-original-title', message).tooltip('show');
  }

  function hideTooltip(btn, duration) {
    setTimeout(() => {
      $(btn).tooltip('hide');
    }, duration);
  }

  const clipboard = new Clipboard('.clipboard-btn');

  clipboard.on('success', (e) => {
    const message = $(e.trigger).attr('data-success');
    setTooltip(e.trigger, message);
    hideTooltip(e.trigger, 1000);
  });

  clipboard.on('error', (e) => {
    const message = $(e.trigger).attr('data-fail');
    setTooltip(e.trigger, message);
    hideTooltip(e.trigger, 3000);
  });
});
