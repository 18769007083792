document.addEventListener('turbolinks:load', () => {
  $('.qr-primary[data-toggle=popover]').each(function () {
    $(this).popover({
      html: true,
      content() {
        const locale = $(this).attr('data-locale');
        return $(`#qr_primary_${locale}_download`).html();
      },
    });
  });

  $('.qr-short[data-toggle=popover]').each(function () {
    $(this).popover({
      html: true,
      content() {
        const locale = $(this).attr('data-locale');
        return $(`#qr_short_${locale}_download`).html();
      },
    });
  });

  $('[data-toggle="popover"]').popover();
});
