import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'vendor';
import 'stylesheets';
import 'shared';
import 'components/signup-tools/signup-tools';
import 'components/navbar/navbar';
import 'components/messages/messages';
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';

Rails.start();
Turbolinks.start();

require.context('../images/', true, /\.(gif|jpg|png|svg)$/i);
