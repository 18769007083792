const gradeSingleAnswer = function () {
  return $('.answer-grade-toggle').click(function () {
    const switchToggle = $(this)
      .closest('.switch')
      .find('.switch-checkbox');
    const lesson = switchToggle.attr('data-lesson');
    const answer = switchToggle.attr('data-answer');

    $.ajax({
      url: `/lessons/${lesson}/answers/${answer}/mark`,
      type: 'get',
    });
  });
};

const fadeUnreadCommentCount = function () {
  return $('.js-comments-expander').click(function () {
    const $wrapper = $(this).closest('.js-comments-wrapper');
    const $unreadMessages = $wrapper.find('.comment.unread_message');
    const $unreadLabel = $wrapper.find('.js-unread-count');
    const unreadLabelNumber = parseInt($unreadLabel.data('count'));
    const $unreadMessagesTotal = $('.js-unread-total-number');
    const unreadMessagesTotalNumber = parseInt($unreadMessagesTotal.text());
    const currentUnreadMessagesNumber = unreadMessagesTotalNumber - unreadLabelNumber;
    return setTimeout(() => {
      if (currentUnreadMessagesNumber > 0) {
        $unreadMessagesTotal.text(currentUnreadMessagesNumber);
      } else {
        $('.js-unread-total-badge').fadeOut(1000);
      }
      $unreadMessages.each((i, val) => {
        $(val).removeClass('unread_message');
        return $unreadLabel.fadeOut(1000);
      });
    }, 2500);
  });
};

const fadeUnreadStudentComments = function () {
  const $unreadMessages = $('.student-comments .comment.unread_message');
  return setTimeout(() => {
    $('.student-comments-count.js-unread-count').fadeOut(1000);
    $unreadMessages.each((i, val) => {
      $(val).removeClass('unread_message');
      return $(val)
        .find('.comment-content small')
        .fadeOut(1000);
    });
  }, 2500);
};

/* eslint-disable consistent-return */
const fadeUnreadMessages = function () {
  if ($('.js-comments-fade').length > 0) {
    const $unreadMessages = $('.comment.unread_message');
    return setTimeout(() => {
      $('.js-unread-total-badge').fadeOut(1000);
      $unreadMessages.each((i, val) => $(val).removeClass('unread_message'));
    }, 2500);
  }
};
/* eslint-enable consistent-return */

document.addEventListener('turbolinks:load', () => {
  gradeSingleAnswer();
  fadeUnreadCommentCount();
  fadeUnreadStudentComments();
  fadeUnreadMessages();
});
