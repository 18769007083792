document.addEventListener('turbolinks:load', () => {
  $('input:text, textarea').focusin(function clearPlaceholder() {
    const placeholderContent = this.placeholder;
    this.placeholder = '';

    $(this).focusout(() => {
      this.placeholder = placeholderContent;
    });
  });
});
