document.addEventListener('turbolinks:load', () => {
  const cvMembersTrigger = document.querySelector('#cv_members_trigger');
  const hasMessageSidebar = document.querySelector('#msg_sidebar');
  const hasNewMessageBadge = document.querySelector('.new-message-badge');
  const onCoachMessagesPage = document.querySelector(
    '#coach_conversation_title',
  );
  const onStudentMessagesPage = document.querySelector(
    '#student_conversation_title',
  );

  const fadeOutNewMessageBadge = () => setTimeout(() => {
    $('.new-message-badge').fadeOut(1000);
  }, 3000);

  const fadeStudentUnreadBadge = () => setTimeout(() => {
    $('#student_unread_badge').fadeOut(1000);
  }, 3000);

  const fadeCoachUnreadMessageCount = () => {
    const coachUnreadBadge = $('#coach_unread_badge');

    if (coachUnreadBadge.text()) {
      const totalUnreadCount = parseInt(coachUnreadBadge.text());
      const cvUnreadCount = parseInt($('#cv_unread_count').data('count'));
      const remainingUnreadCount = totalUnreadCount - cvUnreadCount;

      if (remainingUnreadCount > 0) {
        coachUnreadBadge.text(remainingUnreadCount);
      } else {
        return setTimeout(() => {
          coachUnreadBadge.fadeOut(1000);
        }, 3000);
      }
    }
    return false;
  };

  if (hasMessageSidebar) {
    cvMembersTrigger.addEventListener('click', () => {
      document
        .querySelector('#msg_sidebar')
        .classList.toggle('reveal-msg-sidebar');
      document
        .querySelector('#cv_members_toggle_icon')
        .classList.toggle('fa-ellipsis-v');
      document
        .querySelector('#cv_members_toggle_icon')
        .classList.toggle('fa-times');
    });
  }

  if (hasNewMessageBadge) {
    fadeOutNewMessageBadge();
  }

  if (onStudentMessagesPage) {
    fadeStudentUnreadBadge();
  }

  if (onCoachMessagesPage) {
    fadeCoachUnreadMessageCount();
  }
});
