document.addEventListener('turbolinks:load', () => {
  const hamburger = document.querySelector('.hamburger');
  if (hamburger) {
    hamburger.addEventListener('click', () => {
      if (hamburger.classList) {
        hamburger.classList.toggle('is-active');
      } else {
        const classes = hamburger.className.split(' ');
        const existingIndex = classes.indexOf('is-active');

        if (existingIndex >= 0) {
          classes.splice(existingIndex, 1);
        } else {
          classes.push('is-active');
        }

        hamburger.className = classes.join(' ');
      }
    });
  }
});
