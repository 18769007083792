document.addEventListener('turbolinks:load', () => {
  const hasInviteNowButtons = document.querySelector(
    '#localized_invite_now_triggers',
  );

  if (hasInviteNowButtons) {
    $('button[data-trigger=invite-now]').each(function () {
      const l = $(this).attr('data-locale');
      const inviteNowButton = document.querySelector(
        `#${l}_invite_now_trigger`,
      );
      const inviteCloseButton = document.querySelector(
        `#${l}_invite_close_button`,
      );
      const inviteModeToggleButton = document.querySelector(
        `#${l}_invite_mode_toggle_button`,
      );

      inviteNowButton.addEventListener('click', () => {
        document
          .querySelector(`#${l}_invite_now_overlay`)
          .classList.add('reveal-invite');
        document.querySelector('body').classList.add('modal-open');
      });

      inviteCloseButton.addEventListener('click', () => {
        document
          .querySelector(`#${l}_invite_now_overlay`)
          .classList.remove('reveal-invite');
        document.querySelector('body').classList.remove('modal-open');
      });

      inviteModeToggleButton.addEventListener('click', () => {
        document
          .querySelector(`#${l}_instructions_qr_code`)
          .classList.toggle('d-none');
        document
          .querySelector(`#${l}_instructions_signup_key`)
          .classList.toggle('d-none');
        document
          .querySelector(`#${l}_invite_qr_code`)
          .classList.toggle('d-none');
        document
          .querySelector(`#${l}_invite_signup_key`)
          .classList.toggle('d-none');
        document.querySelector(`#${l}_mode_icon`).classList.toggle('fa-qrcode');
        document.querySelector(`#${l}_mode_icon`).classList.toggle('fa-key');
      });
    });
  }
});
