import { config, library, dom } from '@fortawesome/fontawesome-svg-core';

import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

// Change the config to fix the flicker
config.mutateApproach = 'sync';

library.add(fas, fal, far, fab);

dom.watch();
