document.addEventListener('turbolinks:load', () => {
  // We need to see the address form if:
  // - Online account was set to "No".
  // - Physical delivery was set to "Yes".
  // - A country has been selected.
  const needAddressFields = () => {
    if ($('#student_online_account_false').prop('checked')) {
      return true;
    }
    if ($('#student_physical_delivery_true').prop('checked')) {
      return true;
    }
    if ($('#student_country').val()) {
      return true;
    }

    return false;
  };

  // If we need to see the address fields, then lets see 'em!
  if (needAddressFields()) {
    $('.s_form__address--container').removeClass('d-none');
  }

  const setDeliveryRadioButtons = () => {
    // If online account is set to "No"...
    if ($('#student_online_account_false').prop('checked')) {
      // Display the address form.
      $('.s_form__address--container').removeClass('d-none');

      // Set physical delivery to "Yes".
      $('#student_physical_delivery_true').prop('checked', true);

      // Disable the "No" option for physical delivery.
      $('#student_physical_delivery_false').prop('disabled', true);
    } else if ($('#student_online_account_true').prop('checked')) {
      // Re-enable the "No" option for physical delivery.
      $('#student_physical_delivery_false').prop('disabled', false);
    }
  };

  // Need to run this when the page loads.
  setDeliveryRadioButtons();

  // Listening for a change to the Online Account radio buttons...
  $('.s-form__online-account--radio-buttons').change(() => {
    setDeliveryRadioButtons();
  });

  // Listening for a change on the physical delivery buttons...
  $('.s-form__physical-delivery--radio-buttons').change(() => {
    // If physical delivery is set to "Yes"...
    if ($('#student_physical_delivery_true').prop('checked')) {
      // Display the address form.
      $('.s_form__address--container').removeClass('d-none');
    }
  });

  // If the country value changes...
  $('#student_country').change(() => {
    // Display the address form.
    $('.s_form__address--container').removeClass('d-none');

    // If Ukraine is selected...
    if ($('#student_country').val() === 'UA') {
      // Swap in the Ukrainian address fields.
      $.ajax({
        url: '/coach/students/display_uk_address',
        type: 'get',
      });

      // If United States is selected...
    } else if ($('#student_country').val() === 'US') {
      // Swap in the US address fields.
      $.ajax({
        url: '/coach/students/display_us_address',
        type: 'get',
      });

      // If any other country is selected...
    } else {
      // Swap in the generic address fields.
      $.ajax({
        url: '/coach/students/display_generic_address',
        type: 'get',
      });
    }
  });
});
